// src/components/ProductList.js
import React, { useState, useEffect } from 'react';
import supabase from '../supabaseClient';
import { Link } from 'react-router-dom';

const ProductList = () => {
  const [products, setProducts] = useState([]);

  // 商品一覧を取得
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const { data, error } = await supabase
          .from('shindanshi_plan_table') // Supabaseのテーブル名を指定
          .select('*');

        if (error) {
          console.error('商品情報の取得に失敗しました:', error.message);
        } else {
          setProducts(data);
        }
      } catch (error) {
        console.error('商品情報の取得中にエラーが発生しました:', error);
      }
    };

    fetchProducts();
  }, []);

  return (
    <ul>
      {products.map((product) => (
        <li key={product.id}>
          <h3>{product.name}</h3>
          {product.image_url && (
            <img
              src={product.image_url}
              alt={`Image of ${product.name}`}
              width="300"
            />
          )}
          <p>価格: {product.price}円</p>
          <p>{product.description}</p>
          <Link to={`/payment/${product.id}`}>注文する</Link>
        </li>
      ))}
    </ul>
  );
};

export default ProductList;
