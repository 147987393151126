// src/pages/Signup.js
import React, { useState } from 'react';
import supabase from '../supabaseClient';
import { useNavigate } from 'react-router-dom';
import './Signup.css'; // CSSファイルのインポート

function Signup() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault(); // ページの再読み込みを防ぐ

    try {
      const { user, error } = await supabase.auth.signUp({
        email,
        password,
      });

      if (error) {
        let errorMessage;
        switch (error.message) {
          case 'User already registered':
            errorMessage = 'このメールアドレスはすでに使用されています。';
            break;
          case 'Invalid email format':
            errorMessage = '無効なメールアドレスです。';
            break;
          case 'Password should be at least 6 characters':
            errorMessage = 'パスワードが弱すぎます。もっと強固なものを設定してください。';
            break;
          default:
            errorMessage = '登録に失敗しました。もう一度お試しください。';
        }
        alert(errorMessage);
        console.error('Signup error:', error);
      } else {
        console.log('User created:', user);
        navigate('/dashboard');
      }
    } catch (error) {
      console.error('Unexpected error during signup:', error);
      alert('予期しないエラーが発生しました。もう一度お試しください。');
    }
  };

  return (
    <div className="signup-container">
      <div className="signup-form">
        <h1>新規登録</h1>
        <form onSubmit={handleSubmit}>
          <div>
            <label>
              Email:
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </label>
          </div>
          <div>
            <label>
              Password:
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </label>
          </div>
          <div>
            <button type="submit">登録</button>
          </div>
        </form>
      </div>
      <div className="signup-image">
        <img src="/signup.jpg" alt="Signup illustration" />
      </div>
    </div>
  );
}

export default Signup;