// src/components/Menu.js
import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import supabase from '../supabaseClient'; // Supabaseクライアントをインポート
import './Menu.css'; // CSSファイルをインポート

function Menu() {
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      const { error } = await supabase.auth.signOut(); // Supabaseのサインアウト関数を呼び出し
      if (error) {
        console.error('Logout error:', error);
      } else {
        navigate('/login'); // ログインページにリダイレクト
      }
    } catch (error) {
      console.error('Logout exception:', error);
    }
  };

  return (
    <div className="menu">
      {/* 他のナビゲーションリンク */}
      <NavLink to="/dashboard" className={({ isActive }) => (isActive ? 'active' : undefined)}>ホーム</NavLink>
      <NavLink to="/file-upload" className={({ isActive }) => (isActive ? 'active' : undefined)}>ファイルを送付</NavLink>
      <NavLink to="/answer" className={({ isActive }) => (isActive ? 'active' : undefined)}>添削履歴</NavLink>
      <NavLink to="/profile" className={({ isActive }) => (isActive ? 'active' : undefined)}>プロフィール</NavLink>
      <NavLink to="/how-to-use" className={({ isActive }) => (isActive ? 'active' : undefined)}>利用方法</NavLink> {/* 新しく追加 */}
      {/* ログアウトボタン */}
      <button onClick={handleLogout}>ログアウト</button>
    </div>
  );
}

export default Menu;
