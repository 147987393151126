import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Fileupload from './pages/Fileupload';
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import Answer from './pages/Answer';
import Profile from './pages/Profile';
import Signup from './pages/Signup';
import ProductDetails from './pages/ProductDetails';
import ProductList from './components/ProductList';
import HowToUse from './pages/HowToUse';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import NotFound from './pages/NotFound'; // 404ページのインポート
import './App.css';

// Supabaseクライアントをインポート
import supabase from './supabaseClient';

function App() {
  // ユーザーの状態を管理するステート
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  // 認証状態を監視
  useEffect(() => {
    // Supabaseの認証リスナーを設定
    const { data: authListener } = supabase.auth.onAuthStateChange((event, session) => {
      setUser(session?.user || null); // セッションからユーザー情報を設定
      setLoading(false); // ローディングを終了
    });

    // クリーンアップ
    return () => {
      if (authListener && typeof authListener.unsubscribe === 'function') {
        authListener.unsubscribe(); // サブスクリプションを削除
      }
    };
  }, []);

  // ログイン中にロード状態を表示
  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Router>
      <div className="app-container">
        <header>
          <Navbar />
        </header>
        <div className="content">
          <Routes>
            {/* ログイン状態に基づいたルーティング */}
            <Route path="/" element={!user ? <Login /> : <Navigate to="/dashboard" />} />
            <Route path="/login" element={!user ? <Login /> : <Navigate to="/dashboard" />} />
            <Route path="/signup" element={!user ? <Signup /> : <Navigate to="/dashboard" />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            {user && (
              <>
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/file-upload" element={<Fileupload />} />
                <Route path="/answer" element={<Answer />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/products" element={<ProductList />} />
                <Route path="/product/:id" element={<ProductDetails />} />
                <Route path="/how-to-use" element={<HowToUse />} />
                <Route path="/reset-password" element={<ResetPassword />} />
              </>
            )}
            {/* 404ページのルート */}
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
