// src/components/FileUploadHistory.js
import React, { useState, useEffect } from 'react';
import supabase from '../supabaseClient';

const FileUploadHistory = () => {
  const [uploadHistory, setUploadHistory] = useState([]);

  useEffect(() => {
    const fetchUploadHistory = async () => {
      // Supabaseで現在のユーザーを取得
      const { data: { user }, error: userError } = await supabase.auth.getUser();

      if (userError || !user) {
        console.error('Error fetching user:', userError?.message);
        return;
      }

      // 現在のユーザーに紐づくファイルの履歴を取得
      const { data, error } = await supabase
        .from('user_files') // 適切なテーブル名
        .select('id, filename, upload_time') // 必要なカラムを指定
        .eq('user_id', user.id);

      if (error) {
        console.error('Error fetching upload history:', error.message);
      } else {
        // upload_time を適切な形式に変換
        const files = data.map(file => ({
          ...file,
          upload_time: new Date(file.upload_time).toLocaleString(), // 日時フォーマットの適用
        }));
        setUploadHistory(files);
      }
    };

    fetchUploadHistory();
  }, []);

  return (
    <div className="file-upload-history">
      <h2>アップロード履歴</h2>
      {uploadHistory.length > 0 ? (
        <ul>
          {uploadHistory.map((file) => (
            <li key={file.id}>
              <span>ファイル名: {file.filename}</span> - <span>アップロード日時: {file.upload_time}</span>
            </li>
          ))}
        </ul>
      ) : (
        <p>アップロードされたファイルはありません。</p>
      )}
    </div>
  );
};

export default FileUploadHistory;
