// src/pages/Fileupload.js
import React, { useState, useEffect } from 'react';
import supabase from '../supabaseClient';
import Menu from '../components/Menu';
import './Fileupload.css';

const MAX_FILE_SIZE = 20 * 1024 * 1024; // 20MB

function Fileupload() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [description, setDescription] = useState('');
  const [user, setUser] = useState(null);
  const [purchaseStatus, setPurchaseStatus] = useState('');
  const [error, setError] = useState('');
  const [uploadComplete, setUploadComplete] = useState(false);

  // ユーザー情報を取得
  useEffect(() => {
    const fetchUserData = async () => {
      const { data: { user }, error: userError } = await supabase.auth.getUser();

      if (userError || !user) {
        console.error('Error fetching user:', userError?.message);
        return;
      }

      setUser(user);

      // 購入ステータスを取得
      const { data, error } = await supabase
        .from('users')
        .select('purchaseStatus')
        .eq('id', user.id)
        .single();

      if (error) {
        console.error('Error fetching purchase status:', error.message);
      } else {
        setPurchaseStatus(data?.purchaseStatus || '');
      }
    };

    fetchUserData();
  }, []);

  // ファイル変更イベント
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.size > MAX_FILE_SIZE) {
      alert('ファイルサイズが上限の20MBを超えています。');
      setSelectedFile(null);
      event.target.value = null;
    } else {
      setSelectedFile(file);
    }
  };

  // 説明変更イベント
  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  // ファイル名をサニタイズ
  const sanitizeFilename = (filename) => {
    return filename.replace(/[^a-zA-Z0-9_\u3000-\u303F\u3040-\u309F\u30A0-\u30FF\uFF00-\uFFEF]+/g, '_');
  };

  // ファイルアップロード処理
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!selectedFile || !description) {
      setError('すべての必須項目を入力してください。');
      return;
    }

    if (!user) {
      setError('ユーザーが認証されていません。');
      return;
    }

    setError('');

    try {
      const sanitizedFilename = sanitizeFilename(selectedFile.name);

      // ファイルをSupabaseストレージにアップロード
      const { data, error: uploadError } = await supabase.storage
        .from('user_files')
        .upload(`user_files/${user.id}/${sanitizedFilename}`, selectedFile);

      if (uploadError) {
        console.error('ファイルのアップロード中にエラーが発生しました:', uploadError.message);
        return;
      }

      setUploadComplete(true);
    } catch (error) {
      console.error('予期しないエラーが発生しました:', error);
    }
  };

  return (
    <div className="file-upload-container">
      <div className="menu">
        <Menu />
      </div>
      <div className="file-upload-content">
        <h1 className="file-upload-title">ファイルアップロード</h1>
        <form onSubmit={handleSubmit} className="file-upload-form">
          <div className="description-container">
            <label>
              添削情報: <span className="required">必須</span>
              <input
                type="text"
                value={description}
                onChange={handleDescriptionChange}
                maxLength="50"
                placeholder="添削ご希望の内容を記入ください。"
                required
              />
            </label>
          </div>
          <div className="file-input-container">
            <label>
              ファイル: <span className="required">必須</span>
              <input
                type="file"
                onChange={handleFileChange}
                accept=".pdf"
                required
              />
            </label>
          </div>
          {error && <p className="error-message">{error}</p>}
          <button
            type="submit"
            className={`upload-button ${!purchaseStatus || purchaseStatus !== 'purchased' ? 'disabled' : ''}`}
            disabled={!purchaseStatus || purchaseStatus !== 'purchased'}
          >
            アップロード
          </button>
        </form>
        {uploadComplete && <p className="upload-success-message">アップロードが完了しました。</p>}
      </div>
    </div>
  );
}

export default Fileupload;
