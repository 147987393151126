// src/pages/Login.js
import React, { useState } from 'react';
import supabase from '../supabaseClient';
import './Login.css';
import { useNavigate, Link } from 'react-router-dom';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const { user, error } = await supabase.auth.signInWithPassword({
        email,
        password,
      });

      if (error) {
        console.error('Login error:', error.message);
        alert('ログインに失敗しました: ' + error.message);
      } else {
        console.log('Logged in user:', user);
        navigate('/dashboard');
      }
    } catch (error) {
      console.error('Unexpected error:', error);
      alert('予期しないエラーが発生しました。もう一度お試しください。');
    }
  };

  return (
    <div className="login-container">
      <div className="login-form">
        <h1>ログイン</h1>
        <form onSubmit={handleSubmit}>
          <div>
            <label>
              Email:
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </label>
          </div>
          <div>
            <label>
              Password:
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </label>
          </div>
          <div>
            <button type="submit">ログイン</button>
          </div>
          <p className="forgot-password-link">
            <Link to="/forgot-password">パスワードを忘れた場合はこちら</Link>
          </p>
          <p className="signup-link">
            IDを持っていない方は<Link to="/signup">新規登録</Link>してください。
          </p>
        </form>
      </div>
      <div className="login-image">
        <img src="/login.jpg" alt="Login illustration" />
      </div>
    </div>
  );
}

export default Login;
