// src/pages/Dashboard.js
import React, { useState, useEffect } from 'react';
import supabase from '../supabaseClient';
import Menu from '../components/Menu';
import './Answer.css';
import FileUploadHistory from '../components/FileUploadHistory';

function Dashboard() {
  const [adminAnswers, setAdminAnswers] = useState([]);

  useEffect(() => {
    const fetchAnswers = async () => {
      // ユーザー情報を取得
      const { data: { user }, error: userError } = await supabase.auth.getUser();

      if (userError || !user) {
        console.error('Error fetching user:', userError?.message);
        return;
      }

      try {
        // Supabaseから回答データを取得
        const { data: answers, error } = await supabase
          .from('user_answers') // Supabaseのテーブル名
          .select('*')
          .eq('user_id', user.id);

        if (error) {
          console.error('Error fetching answers:', error.message);
          return;
        }

        // ダウンロードURLを追加する
        const answersWithUrls = await Promise.all(
          answers.map(async (answer) => {
            if (answer.downloadURL) {
              return answer; // すでにダウンロードURLが存在する場合はそのまま返す
            } else {
              const { data: url, error: urlError } = supabase
                .storage
                .from('answers_bucket') // Supabaseストレージのバケット名
                .getPublicUrl(answer.storage_location);

              if (urlError) {
                console.error('Error getting download URL:', urlError.message);
                return answer;
              }

              return { ...answer, downloadURL: url.publicUrl };
            }
          })
        );

        setAdminAnswers(answersWithUrls);
      } catch (error) {
        console.error('Unexpected error fetching answers:', error);
      }
    };

    fetchAnswers();
  }, []);

  // 日付をフォーマットするヘルパー関数
  function formatDate(timestamp) {
    if (!timestamp) return 'Invalid Date';
    try {
      const date = new Date(timestamp);
      return date.toLocaleString();
    } catch (error) {
      console.error('Error formatting date:', error);
      return 'Invalid Date';
    }
  }

  return (
    <div className="answer-page">
      <div className="layout">
        <div className="menu">
          <Menu />
        </div>
        <div className="answer-content">
          <h1>添削履歴</h1>
          <FileUploadHistory />
          <div className="admin-answers">
            <h2>添削済み回答履歴</h2>
            {adminAnswers.length > 0 ? (
              <ul>
                {adminAnswers.map((answer) => (
                  <li key={answer.id}>
                    <span>
                      {answer.downloadURL ? (
                        <a href={answer.downloadURL} target="_blank" rel="noopener noreferrer">
                          {answer.filename}
                        </a>
                      ) : (
                        answer.filename
                      )}
                    </span>
                    <span>アップロード日時: {formatDate(answer.upload_time)}</span>
                  </li>
                ))}
              </ul>
            ) : (
              <p>回答はまだありません。</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
