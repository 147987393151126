// src/pages/Profile.js
import React, { useState, useEffect } from 'react';
import supabase from '../supabaseClient';
import Menu from '../components/Menu';
import './Profile.css';
import Button from '../components/Button';

function Profile() {
  const [profile, setProfile] = useState({
    name: '',
    occupation: '',
    examAttempts: '',
    email: '',
    purchaseStatus: '',
  });
  const [editingField, setEditingField] = useState(null);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        // ユーザー情報を取得
        const { data: { user }, error: userError } = await supabase.auth.getUser();
        if (userError) {
          console.error('Error fetching user:', userError.message);
          return;
        }

        // プロファイル情報を取得
        const { data, error } = await supabase
          .from('users')
          .select('name, occupation, examAttempts, email, purchaseStatus')
          .eq('id', user.id)
          .single();

        if (error) {
          console.error('Error fetching profile:', error.message);
        } else {
          setProfile({
            ...data,
            email: user.email, // Supabaseのユーザー情報からメールアドレスを取得
          });
        }
      } catch (error) {
        console.error('Unexpected error fetching profile:', error);
      }
    };

    fetchProfile();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfile((prevProfile) => ({
      ...prevProfile,
      [name]: value,
    }));
  };

  const handleEditClick = (field) => {
    setEditingField(field);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const { data: { user }, error: userError } = await supabase.auth.getUser();
      if (userError || !user) {
        console.error('Error fetching user for update:', userError?.message);
        return;
      }

      const { error } = await supabase
        .from('users')
        .update({
          [editingField]: profile[editingField],
        })
        .eq('id', user.id);

      if (error) {
        console.error('Error updating profile:', error.message);
      } else {
        setEditingField(null);
      }
    } catch (error) {
      console.error('Unexpected error updating profile:', error);
    }
  };

  return (
    <div className="profile-container">
      <Menu />
      <div className="profile-content">
        <h1 className="profile-title">プロフィール</h1>
        <table className="profile-table">
          <tbody>
            <tr>
              <th>項目</th>
              <th>内容</th>
              <th>編集</th>
            </tr>
            <tr>
              <td>名前</td>
              <td>
                {editingField === 'name' ? (
                  <input
                    type="text"
                    name="name"
                    value={profile.name}
                    onChange={handleChange}
                  />
                ) : (
                  <span>{profile.name}</span>
                )}
              </td>
              <td>
                {editingField !== 'name' && (
                  <button
                    type="button"
                    className="button-link"
                    onClick={() => handleEditClick('name')}
                  >
                    編集
                  </button>
                )}
              </td>
            </tr>
            <tr>
              <td>メールアドレス</td>
              <td>{profile.email}</td>
              <td></td>
            </tr>
            <tr>
              <td>職業</td>
              <td>
                {editingField === 'occupation' ? (
                  <input
                    type="text"
                    name="occupation"
                    value={profile.occupation}
                    onChange={handleChange}
                  />
                ) : (
                  <span>{profile.occupation}</span>
                )}
              </td>
              <td>
                {editingField !== 'occupation' && (
                  <button
                    type="button"
                    className="button-link"
                    onClick={() => handleEditClick('occupation')}
                  >
                    編集
                  </button>
                )}
              </td>
            </tr>
            <tr>
              <td>受験回数</td>
              <td>
                {editingField === 'examAttempts' ? (
                  <input
                    type="number"
                    name="examAttempts"
                    value={profile.examAttempts}
                    onChange={handleChange}
                    min="0"
                  />
                ) : (
                  <span>{profile.examAttempts}</span>
                )}
              </td>
              <td>
                {editingField !== 'examAttempts' && (
                  <button
                    type="button"
                    className="button-link"
                    onClick={() => handleEditClick('examAttempts')}
                  >
                    編集
                  </button>
                )}
              </td>
            </tr>
            <tr>
              <td>購入状況</td>
              <td>
                {profile.purchaseStatus === 'unpurchased'
                  ? '未購入'
                  : profile.purchaseStatus === 'purchased'
                  ? '購入済み'
                  : profile.purchaseStatus === 'reviewed'
                  ? '添削済み'
                  : ''}
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>
        {editingField && (
          <Button text="更新" type="submit" onClick={handleSubmit} />
        )}
      </div>
    </div>
  );
}

export default Profile;
