import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import supabase from '../supabaseClient';
import './ProductDetails.css';

const ProductDetails = () => {
  const { id } = useParams();
  const [product, setProduct] = useState(null);

  useEffect(() => {
    const fetchProductDetails = async () => {
      const { data, error } = await supabase
        .from('shindanshi_plan_table')
        .select('*')
        .eq('id', id)
        .single();

      if (error) {
        console.error('商品の詳細を取得できませんでした:', error.message);
      } else {
        setProduct(data);
      }
    };

    fetchProductDetails();
  }, [id]);

  const handlePayment = async () => {
    try {
      const response = await fetch('http://localhost:4242/create-checkout-session', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          productId: id,
          userId: 'user-id-placeholder', // ユーザーIDを動的に取得
        }),
      });

      const { url } = await response.json();
      if (url) {
        window.open(url, '_blank'); // 新しいタブで開く
      } else {
        alert('決済ページの作成に失敗しました。');
      }
    } catch (error) {
      console.error('決済処理中にエラーが発生しました:', error.message);
    }
  };

  if (!product) {
    return <div>読み込み中...</div>;
  }

  return (
    <div className="layout">
      <div className="product-details">
        <Link to="/dashboard" className="back-link">
          <span>＞ 一覧に戻る</span>
        </Link>
        <h1>{product.name}</h1>
        <p>価格: ¥{product.price}</p>
        <p>{product.description}</p>
        <button className="button-link" onClick={handlePayment}>
          今すぐ購入
        </button>
      </div>
    </div>
  );
};

export default ProductDetails;
