import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import supabase from '../supabaseClient';
import Menu from '../components/Menu';
import './Dashboard.css';

function Dashboard() {
  const [plans, setPlans] = useState([]);

  useEffect(() => {
    const fetchPlans = async () => {
      const { data, error } = await supabase
        .from('shindanshi_plan_table')
        .select('*');

      if (error) {
        console.error('商品情報の取得に失敗しました:', error.message);
      } else {
        setPlans(data);
      }
    };

    fetchPlans();
  }, []);

  return (
    <div className="dashboard-container">
      <div className="menu-container">
        <Menu />
      </div>
      <div className="dashboard-content">
        <h1>商品一覧</h1>
        <table className="table">
          <thead>
            <tr>
              <th>プラン名</th>
              <th>価格</th>
              <th>詳細</th>
              <th>アクション</th>
            </tr>
          </thead>
          <tbody>
            {plans.map((plan) => (
              <tr key={plan.id}>
                <td>{plan.name}</td>
                <td>{plan.price}円</td>
                <td>{plan.description}</td>
                <td>
                  <Link to={`/product/${plan.id}`} className="button-link">
                    詳細
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Dashboard;
