// src/pages/ResetPassword.js
import React, { useState, useEffect } from 'react';
import { useSearchParams, useNavigate, Link } from 'react-router-dom';
import supabase from '../supabaseClient';
import './ResetPassword.css';

function ResetPassword() {
  const [newPassword, setNewPassword] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    const accessToken = searchParams.get('access_token');
    if (!accessToken) {
      setError('無効なリセットコードです。');
    }
  }, [searchParams]);

  const handleReset = async (event) => {
    event.preventDefault();
    const accessToken = searchParams.get('access_token');

    if (!accessToken) {
      setError('無効なリセットコードです。');
      return;
    }

    try {
      const { error: resetError } = await supabase.auth.updateUser({
        password: newPassword,
      });

      if (resetError) {
        console.error('Error resetting password:', resetError.message);
        setError('パスワードのリセットに失敗しました。もう一度お試しください。');
        setMessage('');
      } else {
        setMessage('パスワードがリセットされました。');
        setError('');
        navigate('/login'); // パスワードリセット後にログインページに遷移
      }
    } catch (error) {
      console.error('Unexpected error resetting password:', error);
      setError('予期しないエラーが発生しました。もう一度お試しください。');
      setMessage('');
    }
  };

  return (
    <div className="reset-password-container">
      <div className="reset-password-form">
        <h1>新しいパスワードを設定</h1>
        <form onSubmit={handleReset}>
          <div>
            <label>
              新しいパスワード:
              <input
                type="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
              />
            </label>
          </div>
          <div>
            <button type="submit">パスワードをリセット</button>
          </div>
        </form>
        {message && <p className="reset-password-message">{message}</p>}
        {error && <p className="reset-password-error">{error}</p>}
        <div className="back-to-login">
          <Link to="/login">ログインページに戻る</Link>
        </div>
        <div className="signup-link">
          <p className="info-text">
            IDを持っていない方は <Link to="/signup">新規登録</Link> してください。
          </p>
        </div>
      </div>
      <div className="reset-password-image">
        <img src="/login.jpg" alt="Reset password illustration" />
      </div>
    </div>
  );
}

export default ResetPassword;
