// src/pages/ForgotPassword.js
import React, { useState } from 'react';
import supabase from '../supabaseClient';
import './ForgotPassword.css';

function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const handleReset = async (event) => {
    event.preventDefault();

    try {
      const { error: resetError } = await supabase.auth.resetPasswordForEmail(email, {
        redirectTo:
          window.location.hostname === 'localhost'
            ? 'http://localhost:3000/reset-password'
            : 'https://www.shindanshitensakun.com/reset-password',
      });

      if (resetError) {
        console.error('Error sending password reset email:', resetError.message);
        setError(
          `メール送信に失敗しました。もう一度お試しください。 エラーコード: ${resetError.message}`
        );
        setMessage('');
      } else {
        setMessage(
          '入力されたメールアドレスにパスワードリセットメールを送信しました。メールが届かない場合にはアカウントが存在しないか、入力したメールアドレスが間違っていますので、ご確認ください。'
        );
        setError('');
      }
    } catch (error) {
      console.error('Unexpected error:', error);
      setError('予期しないエラーが発生しました。もう一度お試しください。');
      setMessage('');
    }
  };

  return (
    <div className="forgot-password-container">
      <div className="forgot-password-form">
        <h1>パスワードリセット</h1>
        <form onSubmit={handleReset}>
          <div>
            <label>
              Email:
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </label>
          </div>
          <div>
            <button type="submit">リセットメールを送信</button>
          </div>
        </form>
        {message && <p className="forgot-password-message">{message}</p>}
        {error && <p className="forgot-password-error">{error}</p>}
        <div className="back-to-login">
          <a href="/login">ログインページに戻る</a>
        </div>
        <div className="signup-link">
          <p>
            IDを持っていない方は<a href="/signup">新規登録</a>してください。
          </p>
        </div>
      </div>
      <div className="forgot-password-image">
        <img src="/login.jpg" alt="Forgot password illustration" />
      </div>
    </div>
  );
}

export default ForgotPassword;
