import React from 'react';
import { useNavigate } from 'react-router-dom';
import './NotFound.css';

function NotFound() {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate('/');
  };

  return (
    <div className="notfound-container">
      <div className="notfound-message">
        <h1>404 - ページが見つかりません</h1>
        <p>申し訳ありませんが、お探しのページは存在しません。</p>
        <button onClick={handleGoBack}>ホームに戻る</button>
      </div>
      <div className="notfound-image">
        <img src="/404.jpg" alt="404 illustration" />
      </div>
    </div>
  );
}

export default NotFound;
